<script lang="ts" setup>
import { localeOptions } from '~/utils/locale'

const { locale, setLocale } = useI18n()
const { $log } = useNuxtApp()

const emit = defineEmits(['change'])

const activeLocaleOption = computed(() => {
  return localeOptions.find(localeOption => localeOption.value === locale.value)
})

function changeLocale(localeCode: string) {
  $log.event('locale-switch', localeCode)

  emit('change', localeCode)

  setLocale(localeCode)
}
</script>

<template>
  <div>
    <USelectMenu :modelValue="activeLocaleOption" :options="localeOptions" @change="changeLocale($event.value)">
      <template v-if="activeLocaleOption" #leading>
        <UIcon :name="activeLocaleOption.icon" />
      </template>
    </USelectMenu>
  </div>
</template>
